import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './phones.scss';
import './mobile.scss';
import screen1 from './assets/Screenshot_1706436755.png'
import screen2 from './assets/Screenshot_1706436767.png'
import playBtnImg from './assets/google-play-badge.png'
import {ReactComponent as SatelliteAlt} from "./assets/satellite_alt.svg";
import {ReactComponent as Speed} from "./assets/speed.svg";
import {ReactComponent as MapMarkerDistance} from "./assets/map-marker-distance.svg";
import {ReactComponent as MapMarkerPath} from "./assets/map-marker-path.svg";
import {ReactComponent as GagStation} from "./assets/gas-station.svg";
import {ReactComponent as ArrowUpRight} from "./assets/arrow-up-right.svg";

import {Carousel} from "react-bootstrap";
import {MobileDevice} from "./MobileDevice";

const PLAY_LINK = "https://play.google.com/store/apps/details?id=ua.pp.pm2.speedometr";


function App() {
    return (
        <div className="container  p-0 position-relative bg-white h-100   ">

            <div className="header"></div>

            <div className="text-center play-btn-wrap">
                <a href={PLAY_LINK} target="_blank" className="play-btn disabled">
                    <img src={playBtnImg} alt=""/>
                </a>
            </div>

            <div className="screenshot">
                <div className="wrapper">
                    <div className="device_wrapper angled">
                        <div className="device light">
                            <div className="speaker"></div>
                            <img src={screen2}></img>
                        </div>
                    </div>
                    <div className="device_wrapper">
                        <div className="device dark">
                            {/*<div className="mobile-device-camera"></div>*/}
                            <div className="speaker"></div>
                            <img src={screen1}></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="description p-4 ">


                <div className="content">
                    <b>The speedometer</b> uses GPS to determine speed and distance. Good for older cars and bicycle
                    enthusiasts can be used as an alternative to the on-board speedometer.
                </div>

                <div>
                    <div className="title">Functions:</div>

                    <div className="features-list">

                        <div className="feature-item">
                            <div className="features-list-icon"><Speed/></div>
                            <div>Shows current speed</div>
                        </div>

                        <div className="feature-item">
                            <div className="features-list-icon"><MapMarkerDistance/></div>
                            <div>Distance traveled (per session)</div>
                        </div>

                        <div className="feature-item">
                            <div className="features-list-icon"><MapMarkerPath/></div>
                            <div>Total distance traveled (For all sessions)</div>
                        </div>

                        <div className="feature-item">
                            <div className="features-list-icon"><GagStation/></div>
                            <div>Fuel consumption (if customized)</div>
                        </div>

                        <div className="feature-item">
                            <div className="features-list-icon"><SatelliteAlt/></div>
                            <div>Show number of connected GPS satellites</div>
                        </div>

                    </div>
                </div>
            </div>


            {/*<div className="description full p-4 bg-secondary">
                <Carousel>
                    <Carousel.Item>
                        <div className="slide-img">
                            <img src={screen1} alt=""/>
                        </div>
                        <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="slide-img">
                            <img src={screen2} alt=""/>
                        </div>
                        <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
            </div>*/}

       {/*     <div className="d-flex" style={{perspective: "800px"}}>
            <MobileDevice image={screen1} hasAngle/>
            <MobileDevice image={screen2}/>
            </div>
*/}

            <div className="bottom p-4 bg-dark text-bg-dark">
                <div className="row bottom-info">
                    <div className="col">
                        <a
                            target="_blank"
                            href="https://speedometr.pm2.pp.ua/privacy-policy.html">
                            Privacy Policy
                            <ArrowUpRight/>
                        </a>

                    </div>
                    <div className="col">

                        <a
                            target="_blank"
                            href="mailto:feedbackspring.lab@gmail.com">
                            Contact Us
                            <ArrowUpRight/>
                        </a>
                    </div>
                    <div className="col">
                        <div className="text-secondary credentials">Google Play and the Google Play logo are trademarks of Google LLC.
                        </div>
                    </div>
                </div>
                <div className="text-secondary credentials mt-4 ">&copy; Borys Kotliarov
                </div>
            </div>

        </div>
    );
}

export default App;
